$(function () {

    var inputs = document.querySelectorAll('.hidden-input');
    Array.prototype.forEach.call(inputs, function (input) {
        var label = input.nextElementSibling;
        var labelVal = label.innerHTML;

        input.addEventListener('change', function (e) {
            var fileName = '';
            if (this.files && this.files.length > 1) {
                fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
            } else {
                fileName = e.target.value.split('\\').pop();
            }

            if (fileName) {
                label.querySelector('span').innerHTML = fileName;
            } else {
                label.innerHTML = labelVal;
            }
        }, {passive: true});
    });

    $("form.application-form").submit(function (e) {
        e.preventDefault();

        $(this).find(".has-error").removeClass("has-error");
        $(this).find(".response").removeClass("alert-danger alert-success").addClass("hidden");

        var vacancyId = $("*[name='vacancy_id']").val();
        var name = $("*[name='full_name']").val();
        var email = $("*[name='email']").val();
        var coveringLetter = $("*[name='covering_note']").val();

        var cv = $("input[name='cv-file']").prop('files');

        if (vacancyId == '') {
            applicationFormError('vacancy_id', 'Vacancy ID Not set');
            return false;
        }

        if (name == '') {
            applicationFormError('name', 'Please enter your name');
            return false;
        }

        if (email == '') {
            applicationFormError('email', 'Please enter your email address');
            return false;
        }


        window.app_payload = {
            "site_vacancy_id": vacancyId,
            "full_name": name,
            "email": email,
            "covering_note": coveringLetter
        }


        if (cv.length == 0) {

            LightSpeed.apiCall('recruitment/application', window.app_payload, 'POST', applicationFormMarkComplete, applicationFormFail);
        } else {
            // Upload Attachment
            var file = cv[0];
            if (file) {
                var fileNiceName = file.name.split('.')[0];
                var formData = new FormData();
                formData.append('file', file);
                formData.append('nicename', fileNiceName);
                formData.append('is_public', true);
                formData.append('context', 'VACANCY_APPLICATION');

                LightSpeed.apiCall('storage', formData, 'POST',
                    function (response) {
                        window.storage = response.data.storage;
                        LightSpeed.apiCall('recruitment/application', window.app_payload, 'POST',
                            function (response) {
                                var attachmentPayload = {
                                    "site_vacancy_application_id": response.data.application_id,
                                    "attachment_nicename": window.storage.nicename,
                                    "attachment_context": "CV",
                                    "site_storage_id": window.storage.id
                                }
                                LightSpeed.apiCall('recruitment/application/attachment', attachmentPayload, 'POST', applicationFormMarkComplete, applicationFormFail);
                            },
                            applicationFormFail
                        );
                    },
                    function (response) {
                        //
                    },
                    true);

            }
            //LightSpeed.apiCall('recruitment/application', payload, 'POST', applicationFormAddAttachment, applicationFormFail);
        }


    });

    function applicationFormAddAttachment(response) {
        var file = $("input[name='cv-input']").prop('files')[0];
        if (file) {
            var fileNiceName = file.name.split('.')[0];
            var formData = new FormData();
            formData.append('file', file);
            formData.append('nicename', fileNiceName);
            formData.append('is_public', true);
            formData.append('context', 'VACANCY_APPLICATION');

            LightSpeed.apiCall(
                'storage',
                formData,
                'POST',

                true
            );

        }

    }

    function applicationFormError(field, message) {
        var fieldObj = $("#" + field);
        fieldObj.closest('.form-group').append('<span class="error">' + message + '</span>');
    }

    function applicationFormFail(response) {
        var message = "Oops, something went wrong.<ul>";

        if ((typeof response !== 'undefined') &&
            (typeof response.data !== 'undefined') &&
            (typeof response.data.errors !== 'undefined')) {
            response.data.errors.forEach(function (error) {
                if (typeof LightSpeed.errorCodes[error.code] !== 'undefined') {
                    message += '<li>' + LightSpeed.errorCodes[error.code] + '</li>';
                } else {
                    message += '<li>' + error.code + ': ' + error.message + '</li>';
                }
                if (typeof error.field !== 'undefined') {
                    $("form.application-form *[name='" + error.field + "']").addClass("has-error");
                }
            });
        }

        message += '</ul>';

        $("form.application-form .response").addClass("alert-danger").removeClass("hidden").html(message);
    }

    function applicationFormMarkComplete(response) {
        var applicationId = null;

        if (typeof response.data.application !== 'undefined') {
            applicationId = response.data.application.id;
        }
        if (typeof response.data.application_id !== 'undefined') {
            applicationId = response.data.application_id;
        }

        // Ping Api to inform it the application is complete.
        LightSpeed.apiCall('recruitment/application/' + applicationId, {is_completed: 1}, 'PUT', applicationFinish, applicationFormFail)
    }

    function applicationFinish(response) {
        $("form.application-form").find(".form-footer, .form-group").slideUp();
        $("div.application-form .blue-header").html("Thank you");
        $("form.application-form .response").addClass("alert-success").removeClass("hidden").html('Your application has been submitted');
    }


});
