$(function () {
    if ($('.events-slider .event-slide').length > 3) {
        $('.events-slider').slick({
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            prevArrow: $('.events-slider-button-prev'),
            nextArrow: $('.events-slider-button-next'),
            dots: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        dots: false,
                        arrows: true
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: false,
                        arrows: true
                    }
                }
            ]
        });

    }

});
