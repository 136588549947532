$(document).ready(function(){
    $('#see-other-news-slider').slick({
        slidesToShow: 3,
        dots: false,
        prevArrow: $('.more-news-prev-button'),
        nextArrow: $('.more-news-next-button'),
        responsive: [
            {
                breakpoint: 1024,
                settings:{
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings:{
                    slidesToShow: 2,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings:{
                    slidesToShow: 1,
                    dots: false,
                }
            }
        ]
    });
});
