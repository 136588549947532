$(function(){

    $("#interest-form").submit(function(e){
        e.preventDefault();

        $('input[type="submit"]', this).prop('disabled', true);
        $(this).find(".response").removeClass("alert-danger alert-success").empty();

        LightSpeed.submitContactForm($(this), $(this).data("form-id"), 'POST',
            handleFormSuccess,
            handleFormError
        )

    });

    function handleFormError(response){

        var responseElem = $("#interest-form .response");

        var message = "Oops, something went wrong.";

        // If server error, die early.
        if(typeof response.data === 'undefined' || typeof response.data.errors === 'undefined' || typeof response.data.errors.length == 0){
            responseElem.html(message).addClass("alert-danger");
            return false;
        }

        // if errors loop through and display:
        message += '<ul>';

        response.data.errors.forEach(function(error){

            // Create message. Check if nice message is in LightSpeed.errorCodes
            var errorMessage = '<li>';

            if(typeof LightSpeed.errorCodes[error.code] !== 'undefined'){
                errorMessage += LightSpeed.errorCodes[error.code];
            }else{
                errorMessage += error.message;
            }

            errorMessage += '</li>';

            // If the error has a form field, identify it with has-error class.
            if(typeof error.field !== 'undefined' && error.field !== ''){
                $("#interest-form *[name='" + error.field + "']").addClass("has-error");
            }

            // Append to the message output
            message += errorMessage;

        });

        message += '</ul>';

        // Add message to response

        $('input[type="submit"]', this).prop('disabled', false);

        responseElem.addClass("alert-danger").html(message);

    }

    function handleFormSuccess(response){
        $("#interest-form .form-group").slideUp();
        $("#interest-form .response").addClass("alert-success").html("Thank you for registering your interest.");
    }

});