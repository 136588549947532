$(function () {

    $('.faq-question-container').on('click', function () {
        var faqContent = $(this).children('.faq-content');

        if (faqContent.hasClass('open')) {
            faqContent.removeClass('open').addClass('closed');
            $(this).find('.accordion-expand').removeClass('fa-minus').addClass('fa-plus');
        } else {
            $('.faq-content').removeClass('open').addClass('closed');
            $('.accordion-expand').removeClass('fa-minus').addClass('fa-plus');

            faqContent.toggleClass('open closed');
            $(this).find('.accordion-expand').toggleClass('fa-plus fa-minus');
        }
    });

    if ($('.faq-list').length) {
        $('html, body').delay(1000).animate({
            scrollTop: $('.faq-list').offset().top
        }, 2000);
    }
});
