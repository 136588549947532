$(function () {

    function stripHTML(html){
        // Create a new div element
        var temporalDivElement = document.createElement("div");
        // Set the HTML content with the providen
        temporalDivElement.innerHTML = html;
        // Retrieve the text property of the element (cross-browser support)
        return temporalDivElement.textContent || temporalDivElement.innerText || "";
    }

    function nl2br(str, is_xhtml) {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }

        str = stripHTML(str);
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }

    $('.stockist-container').on('click', function () {
        var faqContent = $(this).children('.stockist-content');
        var id = $(this).attr("data-id");

        var formData = {
            id: id,
        }
        $.ajax({
            type: 'POST',
            url: 'api/stockist-data',
            data: formData,
            success: function (data) {
                var stockist = JSON.parse(data);
                var content = "";
                if (stockist.phone_number !== '' && stockist.phone_number !== '<p></p>') {
                    content += "Phone number: " + stripHTML(stockist.phone_number) + "<br>"
                }
                if (stockist.email_address !== '' && stockist.email_address !== '<p></p>') {
                    content += "Email address: " + stripHTML(stockist.email_address) + "<br>"
                }
                if (stockist.fax_number !== '' && stockist.fax_number !== '<p></p>') {
                    content += "Fax number: " + stripHTML(stockist.fax_number) + "<br>"
                }
                if (stockist.website !== '' && stockist.website !== '<p></p>') {
                    content += "Website: <a href='https://" + stripHTML(stockist.website) + "' target='_blank'>" + stripHTML(stockist.website) + "</a><br>"
                }
                if (stockist.address !== '' && stockist.address !== '<p></p>') {
                    content += "Address: " + nl2br(stockist.address) + "<br>"
                }

                $("#content-for-" + id).html(content);
            }
        });

        if (faqContent.hasClass('open')) {
            faqContent.removeClass('open').addClass('closed');
            $(this).find('.accordion-expand').removeClass('fa-minus').addClass('fa-plus');
        } else {
            $('.stockist-content').removeClass('open').addClass('closed');
            $('.accordion-expand').removeClass('fa-minus').addClass('fa-plus');

            faqContent.toggleClass('open closed');
            $(this).find('.accordion-expand').toggleClass('fa-plus fa-minus');
        }
    });

    if($(".letters-list-item").length) {
        $(".letters-list-item").on("click", function () {
            $(".letters-list-item").removeClass("active");
            $(this).addClass("active");
            var tabContainerTarget = $(this).attr("data-target");
            $(".tab-container").removeClass("active");
            $('.tab-container[data-id="' + tabContainerTarget + '"]').addClass("active");
        });

        $(".letters-list-item:first").click();
    } else{
        $('.tab-container').addClass("active");
        $(".stockists-list .stockist-accordion").css('margin-top', 0)
        $(".stockists-list .stockist-accordion").css('margin-bottom', 0)
        $(".stockists-list").css('margin-bottom', '100px')
    }
});
