$(function () {

    $('#newsletter-large-form').submit(function (e) {
        e.preventDefault();

        $('input[type="submit"]', this).prop('disabled', true);

        var submitBtn = $(this).find("*[type=submit]");
        submitBtn.html('Sending...');

        var formId = $(this).data('form-id');

        var formData = {
            first_name: $(this).find('input[name="first_name"]').val(),
            last_name: $(this).find('input[name="last_name"]').val(),
            email: $(this).find('input[name="email"]').val(),
            subjects: $(this).find('input[name="subject[]"]').val(),
            number_of_horses: $(this).find('input[name="number_of_horses"]').val(),
            message: 'Subscribed',
        };

        var sList = [];
        $("input:checked").each(function () {
            var value = $(this).val();
            sList.push(value);
        });
        formData.subjects = sList;

        formData.name = formData.first_name + " " + formData.last_name;

        $.ajax({
            type: 'POST',
            url: 'api/newsletter-signup',
            data: formData,
            success: function (data) {
                if (data.status === 400) {
                    $('#newsletter-large-form').find('.response p').html('This email is already subscribed.');
                    $('input[type="submit"]', this).prop('disabled', false);
                } else {
                    $('#newsletter-large-form').find('.response p').html('Successfully subscribed.');
                }
            }
        });
        // LightSpeed.submitContactForm(formData, formId, 'POST', formResponseErr(), formResponseSuccess(), false);
    });
});
