$(function () {
    $('#sponsorLogoSlider').slick({
        infinite: true,
        dots: false,
        slidesToShow: 4,
        prevArrow: $('.sponsorships .prev-button'),
        nextArrow: $('.sponsorships .next-button'),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    });
});
