$(function(){

    /**
     * Handle the apply for a role form
     */
    $(".cv-form").submit(function(e){
        e.preventDefault();

        //Sets the loading icon
        // var submitButton = $(this).find("*[type=submit]");
        // submitButton.html('Sending &nbsp;&nbsp;<span class="fa fa-spinner fa-spin"></span>').addClass("sending");

        //Handle file uploads
        uploadFile('cv', true, 'upload_covering_letter');

    });

    /**
     * Uploads a file and returns the relevant data
     *
     * @param inputName
     * @param callBack
     * @param callbackInput
     * @returns {*}
     */
    function uploadFile(inputName, callBack, callbackInput) {

        //Gets the file input
        var fileElement = $('.cv-form').find("input[name="+ inputName +"]");

        // If there are no file in the input goes to the next
        if(!fileElement.prop('files').length > 0) {
            if(callBack) {
                //If there are more files to upload it will go to them
                uploadFile(callbackInput);
            } else {
                //If there not another file it will finish the form submission
                finishVacanciesFormSubmission();
            }
            return;
        }

        // Gets the file
        var file = fileElement.prop('files')[0];

        // Gets the file name
        var fileNiceName = file.name.split('.')[0];

        // Creates formData object and append all the needed data
        var formData = new FormData();
        formData.append('file', file);
        formData.append('nicename', fileNiceName);
        formData.append('is_public', true);
        formData.append('context', 'FORM_SUBMISSION');

        // Makes API call to upload file to storage
        return LightSpeed.apiCall('storage', formData, 'POST', function(response) {
                // Save storage ID in local storage while the other calls complete
                localStorage.setItem(inputName, response.data.storage.id);

                // If call back is true it will upload the next file if not will finish the form submission
                if(callBack) {
                    uploadFile(callbackInput);
                } else {
                    finishVacanciesFormSubmission();
                }

            },
            function (response) {
                // If it fails will stop the loading icon
                $(".cv-form").find("*[type=submit]").text("Re-send").removeClass("sending");
            }, true);

    }

    /**
     * Completes the vacancies form submission
     */
    function finishVacanciesFormSubmission() {

        //Set the form element and form id
        var form = $(".cv-form");
        var formId = form.data('form-id');

        // Create the form object
        var formData = {
            name: form.find('input[name="first_name"]').val() + ' ' + form.find('input[name="last_name"]').val(),
            contact_number: form.find('input[name="phone_number"]').val(),
            email: form.find('input[name="email_address"]').val(),
            cv: form.find('input[name="cv"]').val(),
            uploadCoveringLetter: form.find('input[name="upload_covering_letter"]').val(),
            message: form.find('textarea[name="cover_letter"]').val(),
            fields: {
                job_name: form.find('input[name="job_name"]').val()
            }
        };

        // Sees if a cv file was uploaded
        if(localStorage.getItem('cv') !== null) {
            formData.fields.cv_storage_id = parseInt(localStorage.getItem('cv'));
        }

        // Sees if a cover-letter file was uploaded
        if(localStorage.getItem('cover-letter') !== null) {
            formData.fields.cover_letter_storage_id = parseInt(localStorage.getItem('cover-letter'))
        }


        console.table(formData);

        // Removes the stuff stored in local storage
        localStorage.removeItem('cv');
        localStorage.removeItem('cover-letter');

        // Submits the form with the file ids if there are any
        LightSpeed.apiCall('contact/'+formId+'/submission', formData, 'POST', function(response){
                //vacanciesFormResponse();
                $("#cvModal").modal();
                $(".cv-form").find(".response p").html("Thank you for applying for a role.");
                $(".cv-form").find('input, select, textarea').empty().val('');
            },
            function(response){
                $(".cv-form").find("*[type=submit]").text("Re-send").removeClass("sending");
            });

    }


    /**
     * Resets the form and sets the complete message for the contact form
     */
    function contactFormResponse(){
        $(".cv-form").find(".response p").html("Thank you for getting in contact with us.");
        $(".cv-form").find("*[type=submit]").hide().removeClass("sending");
        $(".cv-form").find('input, select, textarea').empty().val('');
    }


    /**
     * Resets the form and sets the complete message vacancies form
     */
    function vacanciesFormResponse(){

        // $(".cv-form").find("*[type=submit]").hide().removeClass("sending");
        //$('.file-input').closest('.file-label').find('.file-name').html('');
    }

});
