$(function () {

    $('#product-contact-form').submit(function(e) {
        e.preventDefault();

        $('input[type="submit"]', this).prop('disabled', true);

        var submitBtn = $(this).find("*[type=submit]");
        submitBtn.html('Sending...');

        var formId = $(this).data('form-id');

        var formData = {
            name: $(this).find('input[name="name"]').val(),
            email: $(this).find('input[name="email"]').val(),
            contact_number: $(this).find('input[name="contact_number"]').val(),
            address_line_1: $(this).find('input[name="field:address_line_1"]').val(),
            address_line_2: $(this).find('input[name="field:address_line_2"]').val(),
            city: $(this).find('input[name="field:city"]').val(),
            country: $(this).find('select[name="field:country"]').val(),
            postcode: $(this).find('input[name="field:postcode"]').val(),
            preference: $(this).find('input[name="field:preference"]').val(),
            message: $(this).find('textarea#message').val()
        };

        LightSpeed.submitContactForm($(this), formId, 'POST', formResponseErr(), formResponseSuccess(), false);
    });

    function formResponseSuccess(){
        $('#product-contact-form').find('.response p').html('Thank you for your enquiry, we will reply shortly.');
    }

    function formResponseErr(){
        $('#product-contact-form').find('.response p').html('Error posting form, please try again.');
        $('input[type="submit"]', this).prop('disabled', false);
    }

});