var maxHeight = -1;
$('.hero-slider').each(function() {
    if ($(this).height() > maxHeight) {
        maxHeight = $(this).height();
    }
});
$('.hero-section.slick-slide').each(function() {
    if ($(this).height() < maxHeight) {
        $(this).css('margin', Math.ceil((maxHeight-$(this).height())/2) + 'px 0');
    }
});



if ($('.hero-slider').length !== 0) {

    $('.hero-slider').slick({
        infinite: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 5000,
    });


}


