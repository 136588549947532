var stockistIcon, currentIcon, bounds;

function initIcons() {
    stockistIcon = {
        url: "/dod/images/map-marker-stockists.svg",
        scaledSize: new google.maps.Size(28, 55),
        origin: new google.maps.Point(0, -8),
        anchor: new google.maps.Point(0, 55)
    };

    currentIcon = {
        url: "/dod/images/map-marker-current-location.svg",
        scaledSize: new google.maps.Size(28, 55),
        origin: new google.maps.Point(0, -8),
        anchor: new google.maps.Point(0, 55)
    };
}

function initStockistsMap() {
    initMap();

    initIcons();

    var searchRadius = 10; // 10 Miles

    var country = 'gb'; // Default to UK

    var stockists = [];
    var stockistMarkers = [];

    // Disable all map controls, keeping the user at specified locations.
    map.setOptions({
        disableDefaultUI: true,
        draggable: true,
        zoomControl: true,
        scrollwheel: false,
        disableDoubleClickZoom: true,
    });

    map.setCenter(new google.maps.LatLng(51.5074, 0.1278))
    map.setZoom(4)

    var input = document.getElementById('townInput');
    var autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.setFields(
        ['address_components', 'geometry', 'icon', 'name']
    );
    autocomplete.setComponentRestrictions(
        {
            'country': [country]
        }
    );

    function setTownInput() {
        var input = document.getElementById('townInput');
        var autocomplete = new google.maps.places.Autocomplete(input);
        autocomplete.setFields(
            ['address_components', 'geometry', 'icon', 'name']
        );
        autocomplete.setComponentRestrictions(
            {
                'country': [country]
            }
        );
        autocomplete.addListener('place_changed', function () {
            $(".loading-block").show();

            var place = autocomplete.getPlace();

            if (typeof place.geometry === 'undefined') {
                return;
            }
            var placeLat = place.geometry.location.lat();
            var placeLng = place.geometry.location.lng();

            getStockists(placeLat, placeLng);
        });
    }

    $(".stockists select").each(function () {
        var name = $(this).attr("name");
        window['' + name + '-list'] = [];

        $(this).find("option").each(function () {
            if ($(this).val() != '') {
                var obj = {
                    "country": $(this).data("country"),
                    "value": $(this).val(),
                    "text": $(this).text()
                }

                window['' + name + '-list'].push(obj);
            }

        });
    });


    $(".stockists select[name='country']").change(function () {
        $(".loading-block").show();

        country = $(this).val();
        $("#countryName").html($(".stockists select[name='country'] option:selected").text());

        autocomplete.setComponentRestrictions(
            {
                'country': [country]
            }
        );

        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({
            'address': '',
            componentRestrictions: {
                country: country,
            },
        }, function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                map.setCenter(results[0].geometry.location);
                map.setZoom(4)
            } else {
                //
            }
        });

        getStockistsByCountry(country)

    });

    autocomplete.addListener('place_changed', function () {
        $(".loading-block").show();
        var place = autocomplete.getPlace();

        if (typeof place.geometry === 'undefined') {
            return;
        }
        var placeLat = place.geometry.location.lat();
        var placeLng = place.geometry.location.lng();

        getStockists(placeLat, placeLng);
    });


    function getStockists(lat, lng, radius = null) {

        if (radius === null) {
            var radius = $(".radius-selection").val();
        }

        var formData = {
            lat: lat,
            lng: lng,
            radius: radius
        };

        LightSpeed.apiCall('container-by-location', formData, 'GET',
            function (response) {
                stockists = [];
                response.data.containers.forEach(function (stockist) {
                    var stockist = {
                        name: stockist.title,
                        index: stockist.index,
                        distance: stockist.fields.distance,
                        search_type: stockist.fields.search_type,
                        address: stockist.fields.address,
                        lat: parseFloat(stockist.fields.map_lat),
                        lng: parseFloat(stockist.fields.map_lng),
                        phone_number: stockist.fields.phone_number,
                        email_address: stockist.fields.email_address,
                        website: stockist.fields.website,
                    }

                    stockists.push(stockist);
                });

                updateStockistMarkers();

            },
            function (response) {
                clearStockistMarkers();
            });

        $("#currentlat").val(lat)
        $("#currentlng").val(lng)
    }

    function getStockistsByCountry(country) {
        var formData = {
            country: country,
        };

        LightSpeed.apiCall('container-by-country', formData, 'GET',
            function (response) {
                stockists = [];
                response.data.containers.forEach(function (stockist) {
                    var stockist = {
                        name: stockist.title,
                        index: stockist.index,
                        search_type: stockist.fields.search_type,
                        distance: stockist.fields.distance,
                        address: stockist.fields.address,
                        lat: parseFloat(stockist.fields.map_lat),
                        lng: parseFloat(stockist.fields.map_lng),
                        phone_number: stockist.fields.phone_number,
                        email_address: stockist.fields.email_address,
                        website: stockist.fields.website,
                    }
                    stockists.push(stockist);
                });
                updateStockistMarkers();
            },
            function (response) {
                clearStockistMarkers();
            });
    }

    function clearStockistMarkers() {

        stockistMarkers.forEach(function (stockist) {
            stockist.setMap(null);
        });
        stockistMarkers = [];

        $(".map-wrap").removeClass("open");
        $(".stockist-list ul").empty();

    }

    $(".results-search .radius-selection").on("change", function (e) {
        $(".loading-block").show();

        var radius = $(this).val();
        var lat = $("#currentlat").val()
        var lng = $("#currentlng").val()

        getStockists(lat, lng, radius)
    });

    function updateStockistMarkers() {
        clearStockistMarkers();

        $(".landing-search").hide();
        $(".landing-search input.town-input").removeAttr('id');
        $(".results-search input.town-input").attr('id', 'townInput');

        setTownInput()

        bounds = new google.maps.LatLngBounds();

        var locationString = $(".town-input").val();

        if (locationString == '') {
            locationString = $(".town-input").attr('placeholder');
        }
        $(".results-heading .count").text(stockists.length);
        $(".results-heading .location").text(locationString);

        var isCountry = false;

        stockists.forEach(function (stockist, index) {
            if (stockist.search_type === 'country') {
                isCountry = true
            }

            var radius = parseInt($(".radius-selection").val());

            // if(stockist.search_type === 'country') {
            var latlng = {lat: stockist.lat, lng: stockist.lng};

            var phone_number_string = '';
            if (stockist.phone_number) {
                phone_number_string = '<p class="mb-2"><a href="tel:' + stockist.phone_number + '"><i class="fa fa-flip-horizontal fa-phone text-white"></i> ' + stockist.phone_number + '</a></p>';
            }

            var email_string = '';
            if (stockist.email_address) {
                email_string = '<p class="mb-2"><a href="mailto:' + stockist.email_address + '"><i class="fa fa-envelope text-white"></i> ' + stockist.email_address + '</a></p>';
            }

            var website_string = '';
            if (stockist.website) {
                var website = stockist.website.replace(/(^\w+:|^)\/\//, '');
                website_string = '<p><a href="http://' + website + '"><i class="fa fa-mouse-pointer text-white"></i> ' + stockist.website + '</a></p>';
            }

            var contentString = '<div id="markerContent">' +
                '<p id="firstHeading" class="firstHeading"><strong>' + stockist.name + '</strong></p>' +
                '<div id="bodyContent">' +
                '<p>' + stockist.address.replace(/\,/g, '<br>') + '</p>' +
                '<br>' +
                phone_number_string +
                email_string +
                website_string +
                '</div>' +
                '</div>';

            var infowindow = new google.maps.InfoWindow({
                content: contentString
            });

            var stockistMarker = new google.maps.Marker({
                position: latlng,
                map: map,
                label: {
                    text: "" + (index + 1) + "",
                    color: "white"
                },
                icon: stockistIcon,
                title: stockist.name
            });

            stockistMarker.addListener('click', function () {
                infowindow.open(map, stockistMarker);
            });

            google.maps.event.addListenerOnce(map, 'idle', function () {
                jQuery('.gm-style-iw').prev('div').remove();
            });

            var milesString = '';
            if (typeof stockist.distance !== 'undefined') {
                milesString = '<div class="distance other-details">' + stockist.distance + ' miles</div>'
            }

            $(".stockist-list ul").append('<li data-marker="' + stockist.index + '">' +
                '<i>' + (stockist.index + 1) + '</i>' +
                '<h4>' + stockist.name + '</h4>' +
                (stockist.address ? '<div class="address">' + stockist.address + '</div>' : '') +
                (stockist.phone_number ? '<div class="other-details">' + stockist.phone_number + '</div>' : '') +
                (stockist.email_address ? '<div class="other-details">' + stockist.email_address + '</div>' : '') +
                (stockist.website ? '<div class="other-details">' + stockist.website + '</div>' : '') +
                milesString +
                '</li>');

            bounds.extend(latlng);
            stockistMarkers.push(stockistMarker);
            // }
        });

        setupStockistClick();
        $(".map-wrap").addClass("open");

        if (stockistMarkers.length && !isCountry) {
            map.fitBounds(bounds);
        }

        $(".loading-block").hide();
    }

    function setupStockistClick() {
        stockistMarkers.forEach(function (marker, index) {
            marker.addListener('click', function () {
                $(".stockist-list li").removeClass("active");
                $(".stockist-list li[data-marker='" + index + "']").addClass("active");
                setActiveMarker(marker);
            });
        });

        $(".stockist-list li").click(function () {

            var index = parseInt($(this).data('marker'));

            var marker = stockistMarkers[index];
            setActiveMarker(marker);
            $(".stockist-list li").removeClass("active");
            $(this).addClass("active");

        });
    }

    function setActiveMarker(marker) {
        stockistMarkers.forEach(function (marker) {
            marker.setZIndex(1);
            marker.setOpacity(0.5);
        });
        map.setCenter(marker.position);
        map.setZoom(16);
        marker.setZIndex(100);
        marker.setOpacity(1);
        $(".show-all").show();
    }

    function unsetSelection() {
        stockistMarkers.forEach(function (marker) {
            marker.setZIndex(1);
            marker.setOpacity(1);
        });
        $(".stockist-list li").removeClass("active");
        $(".show-all").hide();
    }


    function getLocation() {
        if (navigator.geolocation) {
            $(".town-input").attr("placeholder", "Searching for location").addClass("searching");
            navigator.geolocation.getCurrentPosition(showPosition, function (error) {
                $(".town-input").attr("placeholder", "Geolocation not available").removeClass("searching");
            });
        } else {
            $(".town-input").attr("placeholder", "Geolocation not available");
        }
    }

    function showPosition(position) {

        getStockists(position.coords.latitude, position.coords.longitude);

        if ((typeof map !== 'undefined') && (typeof map.center !== 'undefined')) {
            map.setCenter({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            });
            map.setZoom(15);

            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(position.coords.latitude, position.coords.longitude),
                map: map,
                icon: currentIcon
            });
            $(".town-input").attr("placeholder", "Your current location").removeClass("searching");
        } else {
            $("#stockist-map").attr('data-lat', position.coords.latitude).attr('data-lng', position.coords.longitude).attr('data-zoom', 10).attr('data-show-marker', 1);
            $(".town-input").attr("placeholder", "Your current location").removeClass("searching");
        }
    }

    $("#getCurrentLocation").on("click", function (e) {
        getLocation();
    });
    //getLocation();

    $(".show-all").click(function () {
        map.fitBounds(bounds);
        unsetSelection();
    });

}

$("form.map-form").submit(function (e) {
    e.preventDefault();
});

$(function () {
    if ($('*[data-provide="google-map-stockists"]').length != 0) {
        loadScript('https://maps.googleapis.com/maps/api/js?key=' + gmapsKey + '&callback=initStockistsMap&v=3.exp&use_slippy=true&libraries=places');
    }
});
