if ($('.interests-slider').length !== 0) {

    $('.interests-slider').slick({
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        prevArrow: $('.interests-prev'),
        nextArrow: $('.interests-next'),
        dots: false,
        autoplay: true,
        autoplaySpeed: 5000
    });

}