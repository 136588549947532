$(function () {
    // Open external links in new window. Also adds accessibility tags, and PDF Download option.
    var my_dom = window.location.hostname;
    if (my_dom.indexOf("www.") == 0) {
        my_dom = my_dom.substr(4);
    }
    $("a[href^='http://'],a[href^='https://']").each(function () {
        var this_dom = this.hostname;
        if (this_dom.indexOf("www.") == 0) {
            this_dom = this_dom.substr(4);
        }
        if (this_dom != my_dom) {
            $(this).attr("target", "_blank");
            if ($(this).find(".sr-only").length == 0) {
                $(this).append(' <span class="sr-only">Opens in a new window</span>');
            } else {
                $(this).find(".sr-only").append(' - Opens in a new window');
            }
        }
    });

    $("a").each(function () {
        if ($(this).attr('href').indexOf('.pdf') != -1) {
            $(this).attr("target", "_blank").append('<span class="sr-only">Opens in new window</span>');
            if ($('.get-pdf-link').length == 0) {
                $(this).after('<a href="https://get.adobe.com/uk/reader/" class="get-pdf-link sr-only"><span class="sr-only">Download acrobat reader</span></a>');
            }
        }
    });

    var altCheck = setInterval(function () {
        var count = 0
        $("img").each(function () {
            if ((typeof $(this).attr('alt') === 'undefined')) {
                $(this).attr("alt", 'layout');
                count++;
            }
        });

        if (count == 0) {
            clearInterval(altCheck);
        }

    }, 2000);

    // Search

    $(".search-results").each(function () {
        searchTerm = $(this).data('search');
        if (searchTerm != '') {
            LightSpeed.apiCall('search', {searchTerm: searchTerm, mode: 'DETAILED'}, 'GET', handleSearchResults);
        }
    });

    function handleSearchResults(response) {
        var $ = jQuery;

        $("h1.page-title").text('Search results for "' + searchTerm + '"');

        if (response.data.results.length === 0) {

        } else {

            var results = [];

            var modules = [
                'core_results',
                'events_results',
                'cms_results',
                'product_results',
                'testimonial_results',
            ];

            modules.forEach(function (module) {
                if (typeof response.data.results[module] !== 'undefined' && response.data.results[module].length) {
                    response.data.results[module].forEach(function (result) {
                        if (result.is_deleted == 0) {
                            var content = '';

                            if (result.fields) {
                                result.fields.forEach(function (field) {
                                    if (field.field.identifier.toLowerCase() == 'content' || field.field.identifier == 'description') {
                                        var doc = new DOMParser().parseFromString(field.content.generated_output, 'text/html');
                                        content = doc.body.textContent;
                                    }
                                });
                            }


                            var length = content.length;
                            var title = '';
                            if (length > 140) {
                                content = content.substring(0, 140);
                            }

                            var link = '';
                            /*if ( (result.type.slug) && (result.type.slug != '') ) {
                                link = '/' + result.type.slug + '/' + result.slug;
                            } else {
                                link = '/' + result.slug;
                            }*/

                            if (result.slug) {
                                link = '/' + result.slug;
                            }

                            if (module == 'events_results') {
                                link = '/events' + link;
                            }

                            var tag = ''

                            if (result.type) {
                                link = '/' + result.type.slug + '/' + result.slug;
                                tag = result.type.slug
                            }

                            if (result.title) {
                                title = result.title;
                            }

                            if (result.name) {
                                title = result.name;
                            }

                            const monthNames = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];

                            var updated_at = result.updated_at;
                            var updated_atDate = new Date(updated_at)
                            var timestamp = updated_atDate.getTime()
                            var updated_atMonth = updated_atDate.getMonth()
                            var updated_atMonthName = monthNames[updated_atMonth]

                            var updated_atYear = updated_atDate.getFullYear();

                            results.push({
                                type: tag.replaceAll("/", "-"),
                                result:
                                '<div data-order="'+timestamp + '" class="search-result year-' + updated_atYear + ' month-' + updated_atMonthName + ' type-' + tag.replaceAll("/", "-") + '">\n' +
                                    ' <div class="badge">' + tag + '</div>' +
                                    '                            <h3><a href="' + link + '">' + title + '</a></h3>\n' +
                                    '                            <div class="content">' + content + '</div>' +
                                    '                        </div>'
                            });


                            // Order - Newest appears first
                            var wrapper = $('.search-results');
                            // wrapper.find('.search-result').sort(function(a, b) {
                            //     return +b.dataset.order - +a.dataset.order;
                            // }).appendTo(wrapper);
                        }
                    });
                }
            });

            results.forEach(function (result) {
                if(result.type === 'product'){
                    $('.search-results').append(result.result)
                }
            })

            results.forEach(function (result) {
                if(result.type !== 'product' && result.type !== "-testimonials"){
                    $('.search-results').append(result.result)
                }
            })

            function getSelectedType() {
                return $("#select-category").val();
            }

            function getSelectedMonth() {
                var selected = $("#select-date").find('option:selected');
                return selected.attr("data-month");
            }

            function getSelectedYear() {
                var selected = $("#select-date").find('option:selected');
                return selected.attr("data-year");
            }

            $("#select-category").on("change", function (e) {
                var year = getSelectedYear()
                var month = getSelectedMonth()
                var selected = getSelectedType()
                $(".search-result").hide();

                if (selected === 'all') {
                    if(month === 'all' || year === 'all'){
                        $(".search-result").show();
                    } else{
                        $(".search-result.month-" + month + ".year-" + year).show()
                    }
                } else {
                    if (year === 'all' || month === 'all') {
                        $(".search-result.type-" + selected).show()
                    } else{
                        $(".search-result.month-" + month + ".year-" + year).show()
                    }
                }
            });

            $("#select-date").on("change", function (e) {
                var year = getSelectedYear()
                var month = getSelectedMonth()
                var selectedType = getSelectedType()
                $(".search-result").hide();

                if (year === 'all' || month === 'all'){
                    if (selectedType === 'all') {
                        $(".search-result").show();
                    } else{
                        $(".search-result.type-" + selectedType).show()
                    }
                } else {
                    if (selectedType === 'all') {
                        $(".search-result.month-" + month + ".year-" + year).show()
                    } else{
                        $(".search-result.type-"+selectedType+".month-" + month + ".year-" + year).show()
                    }
                }
            });
        }
    }

    // Start animate of scroll library
    AOS.init();

    $(".accordion section h5").click(function () {

        $(".accordion .open").removeClass("open");
        $(this).parent('section').addClass("open").find('button.additional-content').slideDown();

    });

    $(".accordion button.additional-content").click(function () {

        $(this).parent().find("div.additional-content").addClass('open');
        $(this).slideUp();

    });

    $(".mobile-nav .toggle").click(function () {

        var nav = $(this).parent();

        if (nav.hasClass("open")) {
            nav.removeClass("open");
            $("body").removeClass("nav-open");
        } else {
            nav.addClass("open");
            $("body").addClass("nav-open");
        }

    });

    $(".mobile-nav .has-child i").click(function () {
        if ($(this).hasClass("open")) {
            $(this).removeClass("open");
            $(this).closest('.has-child').find('> ul').slideUp();
        } else {
            $(this).addClass("open");
            $(this).closest('.has-child').find('> ul').slideDown();
        }
        return false;
    });
});

$(function () {
    $('.lazyload-image').lazy();
});

$(function () {
    $('.match-height-news').matchHeight();
    $('.match-height').matchHeight();
});

$(document).ready(function () {
    $("img.lazyload").unveil();
});


$(function () {

    $(".tabs header li").click(function () {

        var thisTabset = $(this).closest('.tabs');

        if (!$(this).hasClass("active")) {
            var getId = $(this).data('id');
            var tab = thisTabset.find('#' + getId);

            // Hide Current tab and unset header
            thisTabset.find('.tab-content > div').removeClass('active expanded');
            thisTabset.find('header li').removeClass('active expanded');

            // Show new tab and toggle header
            tab.addClass('active');
            $(this).addClass("active");


            // Work out if we need to display the show more button
            var height = tab.height();
            var innerHeight = tab.find(".inner").height();
            console.log(innerHeight)
            if (height < innerHeight) {
                tab.addClass("show-more");
            }

        }

    });

    $(".tab-content > div table").each(function () {
        $(this).wrap('<div class="table-wrap">');
    });

    $(".tab-content .button-wrap .read-more").click(function () {
        $(this).closest('.show-more').addClass("expanded");
    });

    $('.factsheet-generic-page .related-products-slider').slick({
        slidesToShow: 5,
        prevArrow: $('.related-products i.prev'),
        nextArrow: $('.related-products i.next'),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1
                }
            }
        ]

    });

    // if ($("#load-products").length) {
    //     $.get("ajax/load-products", function (data) {
    //         $("#load-products").html(data);
    //     }).done(function () {
    //         $(".home-page .category-button").on("click", function () {
                // var cat_id = $(this).attr("data-id");
                // var category_button = $(".category-button");
                // var general_product_slider = $(".products-slider");
                // var general_product_title = $(".products-title");
                // var general_product_button = $(".products-button");
                // var active_slick_product_slider = $(".products-slider.slick-initialized");
                // var clicked_category_slider = $("#products-slider-" + cat_id);
                // var clicked_category_title = $("#products-title-" + cat_id);
                // var clicked_category_button = $("#products-button-" + cat_id);
				//
                // category_button.removeClass("active");
                // general_product_slider.removeClass("d-block");
                // general_product_slider.addClass("d-none");
                // general_product_title.removeClass("d-block");
                // general_product_title.addClass("d-none");
                // general_product_button.removeClass("d-block");
                // general_product_button.addClass("d-none");
                // active_slick_product_slider.slick('unslick');
                // $(this).addClass("active");
				//
                // clicked_category_slider.addClass("d-block");
                // clicked_category_slider.removeClass("d-none");
	$("#products-slider").slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: true,
		dots: true,
		adaptiveHeight: true,

		responsive: [
			{
				breakpoint: 1025,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 769,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		],
		prevArrow: '<button type="button" class="slick-prev"></button>',
		nextArrow: '<button type="button" class="slick-next"></button>',
	});

	$("#logos-slider").slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: true,
		dots: true,
		infinite: false,
		adaptiveHeight: true,

		responsive: [
			{
				breakpoint: 1025,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 769,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		],
		prevArrow: '<button type="button" class="slick-prev"></button>',
		nextArrow: '<button type="button" class="slick-next"></button>',
	});

	$("#factsheet-dropdown").on("change", function(e){
		var selected = $("#factsheet-dropdown option:selected").val()
		if(selected !== 'null') {
			window.location = selected
		}
	});

                // clicked_category_title.addClass("d-block");
                // clicked_category_title.removeClass("d-none");
				//
                // clicked_category_button.addClass("d-block");
                // clicked_category_button.removeClass("d-none");
            // });

            // $(".home-page .category-button:first").click();
        // });
    // }

    $("#trade-area-category-select").on("change", function () {
        var optionSelected = $("option:selected", this);
        window.location = this.value;
    });

    if ($(".category-grid").length) {
        $(".products--grid").hide();
    }

});

function loadScript(url) {
    var script = document.createElement("script"); // Make a script DOM node
    script.src = url; // Set it's src to the provided URL
    document.body.appendChild(script); // Add it to the end of the head section of the page (could change 'head' to 'body' to add it to the end of the body section instead)
}
