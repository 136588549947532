$(function () {

    $('#virtual-yard-form').submit(function(e) {
        e.preventDefault();

        $('input[type="submit"]', this).prop('disabled', true);

        var submitBtn = $(this).find("*[type=submit]");
        submitBtn.html('Sending...');

        var formId = $(this).data('form-id');

        LightSpeed.submitContactForm($(this), formId, 'POST', formResponseErr(), formResponseSuccess(), false);
    });

    function formResponseSuccess(){
        $('#virtual-yard-form').find('.response p').html('Thank you for your enquiry, we will reply shortly.');
    }

    function formResponseErr(){
        $('#virtual-yard-form').find('.response p').html('Error posting form, please try again.');
        $('input[type="submit"]', this).prop('disabled', false);
    }

});
