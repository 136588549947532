var map;
var markerList;
var mapMarkers = [];
var mapLat = 0;
var mapLng = 0;
var bounds;
var infoWindow;
function initMap() {
    // Create a map object and specify the DOM element for display.

    bounds = new google.maps.LatLngBounds();
    $(".map").each(function() {

        var id = $(this).attr('id');

        markerList = $(this).find('.marker');
        var bounds = new google.maps.LatLngBounds();

        mapLat = $(this).data("lat");
        mapLng = $(this).data("lng");

        if($(this).data("zoom")!=''){
            gmapsZoom = $(this).data('zoom');
        }

        map = new google.maps.Map(document.getElementById(id), {
            center: new google.maps.LatLng(mapLat,mapLng),
            zoom: parseInt(gmapsZoom),//comes from map_zoom in cms
        });

        if($(this).data('show-marker')==1){
            var initMarker = new google.maps.Marker({
                position: new google.maps.LatLng(mapLat,mapLng),
                map: map
            });
        }

        infoWindow = new google.maps.InfoWindow({
            content:'Loading...'
        });


        markerList.each(function(){
            addMarker($(this));

        });

    });
}


function addMarker(marker){
    var lat = marker.data('lat');
    var lng = marker.data('lng');
    var icon = marker.data('icon');
    var content = marker.find('.content').html();
    var country = marker.data('country');

    var iconSize = new google.maps.Size(45,45);

    var marker = new google.maps.Marker({
        position: new google.maps.LatLng(lat,lng),
        map: null,
        optimized:false,
        country:country,
        content:content,
        icon: {
            url: icon,
            scaledSize: iconSize,
        }
    });
    addMarkerToMap(marker);
}

function addMarkerToMap(marker){

    marker.setMap(map);

    if(marker.content!='') {

        marker.addListener('click', function(){
            infoWindow.setContent(marker.content);
            infoWindow.open(map, marker);
        });
    }

    mapMarkers.push(marker);
}

function resetMarkers(){
    mapMarkers.forEach(function(marker){
        marker.setMap(null);
    });
    mapMarkers = [];
    bounds = new google.maps.LatLngBounds();
}

// If the element is in the DOM, load the script
$(function(){
    if($('*[data-provide="google-map"]').length!=0){
        loadScript('https://maps.googleapis.com/maps/api/js?key='+gmapsKey+'&callback=initMap&v=3.exp&use_slippy=true');
    }
    function loadScript(url){
        var script = document.createElement("script"); // Make a script DOM node
        script.src = url; // Set it's src to the provided URL
        document.body.appendChild(script); // Add it to the end of the head section of the page (could change 'head' to 'body' to add it to the end of the body section instead)
    }

});
