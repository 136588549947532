$(function(){


    var map = $(".trade-area .map");

    if(typeof map !== 'undefined' && map.length!=0){
        var mapScript = document.createElement('script');
        mapScript.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDyWxCmJe4r9-nJz5Kmly6VJysVW2KZJtg&libraries=places&callback=initProfileMaps';
        document.body.appendChild(mapScript);
    }

});

function initProfileMaps(){
    $(".trade-area .map").each(function(){
        iniProfiletMap($(this));
    });
}


function iniProfiletMap(mapElem){

    var geocoder = new google.maps.Geocoder();
    //maps.each(function(){

    var map = new google.maps.Map(mapElem[0], {
        center:{lat:-34.397, lng:150.644},
        zoom:6
    });

    var iconSize = new google.maps.Size(45,45);
    var mapId = mapElem.data('autocomplete');

    var markerLocation = {};

    var bounds = new google.maps.LatLngBounds();

    var placeSearch, autocomplete, marker;

    var autoElement = $("*[name='"+mapId+"_addressInput']")[0];
    var inputElement = $("#"+mapId);


    map.addListener('zoom_changed', function(){
        markerLocation.zoom = map.getZoom();
        inputElement.val(JSON.stringify(markerLocation));
    });

    if(inputElement.val()!='' && typeof inputElement.val() !== 'undefined'){
        markerLocation = JSON.parse(inputElement.val());
        if(typeof markerLocation.geometry !== 'undefined'){
            marker = new google.maps.Marker({
                map:map,
                position:markerLocation.geometry.location,
                draggable:true,
                icon: {
                    url: '/dod/images/map-marker.svg',
                    scaledSize: iconSize,
                }
            });
            marker.addListener('dragend', function(){
                geocodePosition(marker.getPosition(), $(autoElement), inputElement, markerLocation);
            });
            bounds.union(markerLocation.geometry.viewport);
            map.fitBounds(bounds);
            autoElement.value = markerLocation.address;
        }
    }

    autocomplete = new google.maps.places.Autocomplete(
        (autoElement),
        {types: ['geocode']}
    );

    autocomplete.addListener('place_changed', function(){

        if(typeof marker !== 'undefined') {
            marker.setMap(null);
        }


        var place = autocomplete.getPlace();

        marker = new google.maps.Marker({
            map:map,
            title:place.formatted_address,
            position:place.geometry.location,
            draggable:true,
            icon: {
                url: '/dod/images/map-marker.svg',
                scaledSize: iconSize,
            }
        });
        marker.addListener('dragend', function(){
            geocodePosition(marker.getPosition(), $(autoElement), inputElement, markerLocation);
        });

        if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
        } else {
            bounds.extend(place.geometry.location);
        }

        map.fitBounds(bounds);


        updateLocation(markerLocation, place, inputElement);


    });

    //});

    function updateLocation(markerLocation, place, input){
        markerLocation.address = place.formatted_address;
        markerLocation.lat = place.geometry.location.lat();
        markerLocation.lng = place.geometry.location.lng();
        markerLocation.geometry = place.geometry;

        place.address_components.forEach(function(component){
            if(component.types.indexOf('country') != -1){
                markerLocation.country = component.long_name;
            }
            if(component.types.indexOf('administrative_area_level_2') != -1){
                markerLocation.region = component.long_name;
            }
        });

        input.val(JSON.stringify(markerLocation)).trigger('change');
        $("input#map_lat").val(markerLocation.lat);
        $("input#map_lng").val(markerLocation.lng);
    }

    function geocodePosition(pos, input, inputElement, markerLocation) {
        geocoder.geocode({
            latLng: pos
        }, function(responses) {
            if (responses && responses.length > 0) {
                input.val(responses[0].formatted_address);
                updateLocation(markerLocation, responses[0], inputElement);
            } else {
                input.val('Cannot determine address at this location.');
            }
        });
    }
}
