if ($('.home-main-slider').length !== 0) {

    $('.desktop-slider .home-main-slider').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 5000
    });

    $(".desktop-slider .home-main-slider").on('afterChange', function (event, slick, currentSlide, nextSlide) {
        var slide = $(".desktop-slider .home-main-slider").find("[data-id='" + currentSlide + "']");
        var slideImage = $(".desktop-slider .home-main-slider .slick-active").attr("data-image");
        var imageContainer = $(".desktop-slider .main-slider-image");
        imageContainer.fadeOut(200, function () {
            imageContainer.css("background-image", "url('" + slideImage + "')");
            imageContainer.fadeIn(200);
        });
    });


    $('.mobile-slider .home-main-slider').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 5000
    });

    $(".mobile-slider  .home-main-slider").on('afterChange', function (event, slick, currentSlide, nextSlide) {
        var slide = $(".mobile-slider .home-main-slider").find("[data-id='" + currentSlide + "']");
        var slideImage = $(".mobile-slider .home-main-slider .slick-active").attr("data-image");
        var imageContainer = $(".mobile-slider .main-slider-image");
		console.log(slideImage)
        imageContainer.fadeOut(200, function () {
            imageContainer.css("background-image", "url('" + slideImage + "')");
            imageContainer.fadeIn(200);
        });
    });
}

//
// $(".home-page .products-slider").slick({
//     slidesToShow:4,
//     slidesToScroll:1,
//     arrows:false,
//     asNavFor:'.slider-nav',
//     adaptiveHeight:true
// });
