// WORK IN PROGRESS BELOW

$('document').ready(function () {

    $('.lang-select').click(function() {
        var theLang = $(this).attr('data-lang');
        $('.goog-te-combo').val(theLang);

        //alert(jQuery(this).attr('href'));
        window.location = $(this).attr('href');
        location.reload();

    });

    $('.ct-language').css("cursor", "pointer");
    $('.ct-language').off('click').on('click', function(){
        $('.ct-language__dropdown').toggleClass('open');
        // if($('.ct-language__dropdown').hasClass('open')){
        //     $('.ct-language__dropdown').removeClass('open');
        // } else{
        //     $('.ct-language__dropdown').addClass('open');
        // }
    });

    $('.ct-language__dropdown a').off('click').on('click', function(){
        var langTitle = $(this).data('title');
        var langCode = $(this).data('lang');
        var localLang = localStorage.setItem('lang', langTitle);
        localStorage.setItem('langCode', langCode);
        $('.ct-language').html(localStorage.getItem('lang'));
    });


    $('#google_translate_element').one("click", function () {

        // Change font family and color
        var $frame = $('.goog-te-menu-frame:first');
        $frame.css('box-shadow', 'none');
        $frame.css('height', '500px !important');
        $frame.contents().find('.goog-te-menu2').css({'border-radius':'7px', 'padding':'0', 'height':'500px !important'});
        $frame.contents().find(".goog-te-menu2-item div")
            .css({
                'color': 'black',
                'font-family': 'roboto',

            }).hover(function(){
            $(this).css({'background':'rgba(217,189,69, 1)', 'color': 'white'});
        },function(){
            $(this).css({"background":"", "color":"black"});
        });


    });

    translateProduct();

});



function translateProduct(){
    var langCode = localStorage.getItem('langCode', langCode);

    if(langCode==null){
        langCode = 'en';
        localStorage.setItem('langCode', langCode);
    }

    $(".products-single .language-wrap").each(function(){

        // do we have content for this language?
        if($(this).find("*[lang='"+ langCode +"']").length==0){
            // if not, hide all languages, display english.
            $(this).find("*[lang]").addClass("lang-hide");
            $(this).find("*[lang='en']").removeClass("lang-hide");

        }else {
            // we do, so hide everything, and show the language we're looking for.
            $(this).find("*[lang]").addClass("lang-hide");
            $(this).find("*[lang='" + langCode + "']").removeClass("lang-hide");
        }

    });

}
