$(function(){
    if ($('.testimonials-slider').length !== 0) {

        $('.testimonials-slider').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: $('.testimonials-slider-button-prev'),
            nextArrow: $('.testimonials-slider-button-next'),
            dots: false
        });

    }

});
