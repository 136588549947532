$(function(){
    $('.other-employee-slider').slick({
        infinite: true,
        slidesToShow: 3,
        prevArrow: $('.other-employee.prev-button'),
        nextArrow: $('.other-employee.next-button'),
        dots: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    });
});
