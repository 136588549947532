function getAllStockists(){

    var formData = {
        lat: '0',
        lng: '0'
    };

    LightSpeed.apiCall('stockists', formData, 'GET',
        function(response){
            stockists = [];

            response.data.content.forEach(function(stockist){
                var stockist = {
                    id: stockist.id,
                    name: stockist.title
                }
                stockists.push(stockist);
            });

            $.each(stockists, function (index, stockist) {
                $('#stockists-select').append($('<option/>', {
                    value: stockist.id,
                    text : stockist.name
                }));
            });

            $("#stockists-select").attr("disabled", false);
        },
        function(response){
        //
        });
}


if ($('#stockists-select').length !== 0) {
    getAllStockists();
}
